<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Условия мотивации для&nbsp;сотрудников оптик от&nbsp;компании ООО «Линзы&nbsp;Хойя&nbsp;Рус»</h1>
          <p class="lead accent">Сроки проведения: <b><nobr>01.06.2019</nobr>&nbsp;–&nbsp;<nobr>31.08.2019 г.</nobr></b></p>
          <p class="lead accent">
            Линзы, за продажу которых будут начисляться баллы (см. таблицу ниже):<br>
            &ndash; Фотохромные линзы Sensity Stoc SHV 1,5 и 1,6<br>
            &ndash; Линзы, участвующие в Акции «Встречайте солнце с Hoya!»
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="accent text-center" style="width: 60%;">Типы линз</th>
                <th class="accent text-center">Количество баллов (=рублей) за пару линз</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Рецептурные окрашенные или поляризационные линзы однофокального, прогрессивного и бифокального дизайнов (офисные линзы не участвуют) </td>
                <td class="text-center">200</td>
              </tr>
              <tr>
                <td>Складские окрашенные солнцезащитные и поляризационные линзы (склад Европа) - Hoyatint UV 1.50, Hoyatint UV 1.60, Hoya 1.50 Polarized, Hilux Eyas 1.60 Color, Hilux 1.50 Color</td>
                <td class="text-center">100</td>
              </tr>
              <tr>
                <td>Складские фотохромные линзы Sensity 1,5 и 1,6 SHV(склад Москва)</td>
                <td class="text-center">150</td>
              </tr>
              <tr>
                <td>Рецептурные фотохромные линзы Sensity, Sensity Dark, Sensity Shine</td>
                <td class="text-center">300</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 col-md-10">
          <h4 class="accent">Условия проведения:</h4>
          <p>В&nbsp;период проведения мотивационной программы* заказывайте линзы Hoya, участвующие в&nbsp;Акции (см.&nbsp;таблицу выше) <b>через HoyaiLog</b>, и&nbsp;получайте баллы за&nbsp;каждую проданную пару линз!</p>
          <p>Чтобы получить баллы за&nbsp;продажи, необходимо при размещении заказов через систему HoyaiLog указывать ваш номер мобильного телефона в&nbsp;поле &laquo;Справочный номер&raquo;&nbsp;&mdash; см.&nbsp;пример ниже:</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <img class="d-block mw-100" src="../assets/hoyailog.png">
          <br>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 col-md-10">
          <p><em>* Программа заканчивается <nobr>31.08.2019</nobr> года. Таким образом, будут учитываться только заказы, размещенные в&nbsp;HoyaiLog до&nbsp;23 часов 59&nbsp;минут по&nbsp;Московскому времени 31 августа 2019 года.</em></p>
          <p>Номер телефона должен вноситься в&nbsp;<b>HoyaiLog</b> строго в&nbsp;формате 79999999999 (без плюсиков, восьмерок, скобок, дефисов и&nbsp;прочих знаков&nbsp;&mdash; итого только 11 цифр). Клиентам, неверно указавшим номера мобильных телефонов в&nbsp;HoyaiLog, баллы начисляться не&nbsp;будут!</p>
          <p>Баллы (= рубли) вы&nbsp;сможете обменять на&nbsp;электронные подарочные сертификаты известных сетевых брендов (ознакомиться со&nbsp;списком сертификатов вы&nbsp;можете на&nbsp;сайте <a href="https://www.giftery.ru/giftcards">www.giftery.ru/giftcards</a>).</p>
          <p>Накопленные вами баллы станут доступны на&nbsp;сайте <a href="https://hoya.giftery.shop">hoya.giftery.shop</a> <b>после окончания мотивационной программы и&nbsp;подведения ее&nbsp;итогов</b> (о&nbsp;точных сроках вам сообщат ваши менеджеры Hoya, но&nbsp;не&nbsp;ранее <nobr>23.09.2019</nobr>). <b>Баллы необходимо потратить до&nbsp;31 октября 2019, так как 1 ноября они сгорят!</b></p>

          <p class="red"><b>ВАЖНО! В случае, если накопленная вами сумма баллов превышает 3 999 рублей в год, вы обязаны самостоятельно подать информацию об этом в налоговую инспекцию и уплатить подоходный налог с суммы, превышающей данный лимит.</b></p>

          <p class="accent text-center bold">Остались вопросы?<br>
          Вы&nbsp;можете задать их&nbsp;своему менеджеру Hoya или по<br>
          телефону клиентского сервиса <nobr><a href="tel:8 (800) 770-06-61">8 (800) 770-06-61</a></nobr>.<br>
          Желаем вам успешных продаж!</p>

          <hr>

          <br>
          <div class="text-center">
            <router-link class="button button--rounded button--large" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
          </div>
          <br>
          <br>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
}
</script>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .red {
    color: #c00;
  }

  .bold,
  b {
    font-weight: bold;
  }

  .italic,
  em {
    font-style: italic;
  }

  .accent {
    color: var(--accent-color);
  }

  p {
    color: #333;
  }

  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 60%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 49%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 49%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }
</style>
